.client-card {
    /* border-radius: 8px; */
    border: 1px solid rgba(4, 0, 0, 0.2);
    /* box-shadow: rgb(0 0 0 / 12%) 0px 2px 10px 0px; */
}

.client-card .ant-card-head {
    border-bottom: 1px solid rgba(4, 0, 0, 0.2);
}

.client-list {
    font-size: 12px;
}

.status-deactive-btn {
    background-color: rgb(118, 118, 118);
    border-color: rgb(118, 118, 118);
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}

.status-active-btn {
    background-color: green;
    border-color: green;
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}


.user-delete-btn {
    background-color: red;
    border-color: red;
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}

.user-search-btn {
    /* border-radius: 8px; */
    width: 120px;
}


.client-tabs .ant-tabs-nav-list {
    display: flex;
    flex-flow: 1;
    width: 100%;
}

.client-tabs .ant-tabs-nav-list .ant-tabs-tab {
    justify-content: center;
    flex-grow: 1;
    width: 100%;
}

.history-table .ant-table-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.history-table .ant-table-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.client-phone {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    min-height: 32px;
}


.ips-table .ant-table-row .ant-table-cell {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.ips-table .ant-table-thead .ant-table-cell {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.address-hover-color:hover {
    color: blue;
  }

.ant-list .ant-list-item .ant-list-item-action{
margin-inline-start: 0px;
  }

  /* CSS for mobile responsiveness */
/* @media screen and (max-width: 767px) {
    .responsive-card {
      width: 100%; /* Make the card width 100% for small screens 
    }
  
    .responsive-table {
      overflow-x: auto; /* Add horizontal scrollbar if the table overflows the screen 
    }
  
    .responsive-table .ant-table table {
      width: 100% !important; /* Make the table width 100% for small screens 
    }
  
    .responsive-table .ant-table-thead th,
    .responsive-table .ant-table-tbody td {
      display: block; /* Display table header and body cells as block elements 
      text-align: left; /* Align text to the left 
    }
  
    .responsive-table .ant-table-thead th {
      display: none; /* Hide table headers in mobile view 
    }
  
    .responsive-table .ant-table-tbody td {
      padding: 8px; /* Add padding to table cells 
      border: none; /* Remove cell borders 
    }
  } */
  