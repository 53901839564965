body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-layout.full {
  min-height: 100vh;
}

.ant-layout.full .content {
  margin: 16px;
  padding: 12px;
  background-color: #fff;
  color: #000;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.ant-layout.full .breadcrumb {
  margin: 16px;
  margin-bottom: 0px;
}

div.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
}

div.loading img {
  max-width: 200px;
}

.ant-list-item-meta-title .role {
  color: #4267b2;
  font-weight: bold;
}

.ant-list-item-meta-description .time {
  margin-left: 10px;
  font-size: 11px;
}
.box {
  position: relative;
  display: inline-block;
  border: 1px solid rgba(140, 140, 140, 0.35);
  border-radius: 2px;
  transition: all 0.2s;
  padding: 16px;
}

.PhoneInput {
  border: 1px solid #ccc;
  padding: 3px 11px;
  /* border-color: var(--ant-primary-color-hover); */
  /* box-shadow: 0 0 0 2px var(--ant-primary-color-outline); */
  border-right-width: 1px;
  outline: 0;
}

.PhoneInputInput {
  border: 0px;
}

.ant-menu-item-icon {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.ant-btn-icon-only.custom-icon {
  line-height: 30px;
}

.ant-table-wrapper {
  margin-top: 10px;
}

.tox-tinymce {
  border-width: 1px !important;
  border-radius: 3px !important;
  border-color: #d9d9d9 !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #4267b2;
}

.ant-upload-picture-card-wrapper.large .ant-upload.ant-upload-select-picture-card {
  width: 900px;
  height: auto;
  max-width: 100%;
  aspect-ratio: 1.5;
}

.ant-upload-picture-card-wrapper.small .ant-upload.ant-upload-select-picture-card {
  width: 90px;
  height: 90px;
  max-width: 100%;
}

.custom-label {
  line-height: 32px;
  margin-right: 8px;
}

.upload-section {
  width: 900px;
  height: auto;
  max-width: 100%;
  aspect-ratio: 1.5;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-section.porfolio {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upload-section.author {
  width: 95px;
  height: 95px;
}

.media-preview {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
}
.media-preview:hover {
  color: #4267b2;
  border: 1px solid #4267b2;
}

.media-preview.selected {
  border: 1px solid #4267b2;
}

.media-metadata {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  padding: 5px;
}

.media-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ant-transfer-list-content-item-text {
  white-space: normal;
}

.ant-transfer-list-content-item-text .main {
  color: #999;
  font-size: 11px;
}

.ant-transfer-list-content-item-text .sub {
  color: #4267b2;
  font-size: 11px;
}

.relate-item-row .ant-input[disabled] {
  color: #000;
}

.ant-table table .news-row {
  cursor: pointer;
}

.border-left {
  border-left: 1px solid #eee;
}

.screen.loading {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ant-upload.ant-upload-drag {
  height: auto;
}

.ant-form-item.hidden-item {
  line-height: 0px;
  margin: 0px;
}
.ant-form-item.hidden-item .ant-form-item-control-input {
  height: 0px;
  min-height: 0px;
}
