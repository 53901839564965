#operators .title-list {
  display: flex;
  align-items: center;
}

#operators .online-circle {
  margin-left: 10px;
  width: 10px !important;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  overflow: visible;
}

#operators .offline-circle {
  margin-left: 10px;
  width: 10px !important;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}
