.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f2f5;
}
.ant-layout-sider {
  background-color: #fff !important;
}
.ant-layout-sider .logo {
  height: 52px;
  margin: 4px;
  background-color: #fff;
  text-align: center;
}
.ant-layout-sider .logo img {
  height: 100%;
}
.ant-layout-header {
  padding: 0px 20px !important;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  background-color: #4267b2;
  color: rgb(255, 255, 255);
  overflow: hidden;
}
.ant-layout-header .trigger {
  background-color: #4267b2;
  border-width: 0px;
  color: rgb(255, 255, 255);
}
.header-left {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.header-right {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.header-title {
  font-size: 18px;
  font-weight: "bold";
}

.ant-menu-vertical .ant-menu-submenu-selected {
  background-color: #f0f2f5;
}

.ant-layout-header .ant-col {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.ant-layout-header .ant-col button {
  background-color: transparent;
  color: #fff;
}
