.ant-layout-content.login {
  justify-content: center;
  min-height: 280px;
  display: flex;
}
.ant-layout-content.login .ant-form {
  width: 100%;
}
.ant-layout-content.login .center {
  width: 350px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
}
.login-logo {
  max-width: 150px;
  margin-bottom: 20px;
  margin-top: 20px;
}
