.partner-card {
    /* border-radius: 8px; */
    border: 1px solid rgba(4, 0, 0, 0.2);
    /* box-shadow: rgb(0 0 0 / 12%) 0px 2px 10px 0px; */
}

.partner-card .ant-card-head {
    border-bottom: 1px solid rgba(4, 0, 0, 0.2);
}

.partner-list {
    font-size: 12px;
}

.status-deactive-btn {
    background-color: rgb(118, 118, 118);
    border-color: rgb(118, 118, 118);
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}

.status-active-btn {
    background-color: green;
    border-color: green;
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}


.user-delete-btn {
    background-color: red;
    border-color: red;
    color: white;
    width: 120px;
    /* border-radius: 8px; */
}

.user-search-btn {
    /* border-radius: 8px; */
    width: 120px;
}


.partner-tabs .ant-tabs-nav-list {
    display: flex;
    flex-flow: 1;
    width: 100%;
}

.partner-tabs .ant-tabs-nav-list .ant-tabs-tab {
    justify-content: center;
    flex-grow: 1;
    width: 100%;
}

.history-table .ant-table-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.history-table .ant-table-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.partner-phone {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    min-height: 32px;
}


.ips-table .ant-table-row .ant-table-cell {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.ips-table .ant-table-thead .ant-table-cell {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.ant-input-addon-before:focus {
    outline: none;
  }