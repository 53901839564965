/* border */
.bd-r-thin {
  border-right: 0.2px solid rgba(0, 0, 0, 0.3);
}

/* // magin */

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

/* // padding */

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.p-20 {
  padding: 20px;
}

/* fontsize */

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

/* width */
.w-100 {
  width: 100px;
}

.w-150 {
  min-width: 150px;
}

.w-180 {
  min-width: 180px;
}

.w-200 {
  width: 200px;
}

/* ant-phone */

.ant-phone {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  min-height: 32px;
}

/* border */
.border {
  border: 1px solid #f1f1f1;
}

/* // CSS TESSE */

/* border */
.border {
  border: 1px solid #f1f1f1;
}

/* color */
.text-black {
  color: black;
}

/* background */
.bg-danger {
  background: #ef5350;
  color: white;
}

/* ---- CSS SPACE ---- */
/* vertical-align */
.align-super {
  vertical-align: super;
}

/* white-space */
.white-space-nowrap {
  white-space: nowrap;
}

/* display */
.flex {
  display: flex;
}

.hidden {
  display: none;
}

@media (min-width: 576px) {
  .sm-initial {
    display: initial;
  }

  .sm-hidden {
    display: none;
  }
}

/* flex-direction */
.flex-col {
  flex-direction: column;
}

/* align-items */
.items-center {
  align-items: center;
}

/* justify-content */
.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

/* overflow */
.overflow-auto {
  overflow: auto;
}

/* height */
.h-full {
  height: 100%;
}

/* width */
.w-full {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-sm {
  width: 300px;
}

.w-xs {
  width: 200px;
}

.w-100 {
  width: 100px;
}

.max-w-sm {
  max-width: 300px;
}

.max-w-xxs {
  max-width: 110px;
}

/* margin */
.mb-0 {
  margin-bottom: 0;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

/* padding */
.pr-30 {
  padding-right: 30px;
}

.p-20 {
  padding: 20px;
}

/* ---- END CSS SPACE  ---- */
/* ---- CSS TEXT ---- */
/* font-size */
.text-14 {
  font-size: 14px;
}

.text-20 {
  font-size: 20px;
}

.text-title {
  font-size: 1.7rem;
}

/* font-weight */
.font-bold {
  font-weight: 600;
}

/* text-align  */
.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* text-transform */
.text-capitalize {
  text-transform: capitalize;
}

/*  truncate  */
.truncate-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ---- END CSS TEXT ---- */
/* ---- CSS CURSOR ---- */
/* cursor */
.cursor-pointer {
  cursor: pointer;
}

/* border */
.bd-r-thin {
  border-right: 0.2px solid rgba(0, 0, 0, 0.3);
}

/* // magin */

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

/* // padding */

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

/* fontsize */
.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.ant-phone {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding-left: 11px !important;
  padding: 0px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  min-height: 32px !important;
}

.ant-phone .PhoneInputInput  {
  font-size: 14px !important;
  padding: 4px 11px;
}

.ant-phone [disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.00);
  font-size: 14px;
}



.radio-button {
  width: 50%;
  text-align: center;
}