.btn-ips-view {
    border-color: #4267b2;
    color: #4267b2;
    height: 25px;
    width: 25px;
    margin: 0px;
    padding: 0px;
}

.btn-ips-edit {
    border-color: orange;
    color: orange;
    height: 25px;
    width: 25px;
    margin: 0px;
    padding: 0px;
}

.btn-ips-delete {
    border-color: red;
    color: red;
    height: 25px;
    width: 25px;
    margin: 0px;
    padding: 0px;
}

.icon-ips-action {
    margin: 0px;
    padding: 0px;
}

.my-radio-group .ant-radio-wrapper {
    font-size: 14px;
}

.btn-ips-action {
    display: inline-block; 
    margin-right: 3px; 
    /* margin-bottom: 2px; */
  }
  
  @media (max-width: 1400px) {
    .btn-ips-action {
      display: block; 
      margin-bottom: 2px; 
    }
  }

  /* @media (max-width: 1280px) {
    .btn-ips-edit {
      display: inline-block; 
      width: 15px;
      height: 15px;
    }
    .btn-ips-view {
        display: inline-block; 
        width: 15px;
        height: 15px;
      }
      .btn-ips-delete {
        display: inline-block; 
        width: 15px;
        height: 15px;
      }
      .icon-ips-action {
        width: 5px;
        height: 5px;
      }
  } */
  
